<template>
  <div class="flex flex-col">
    <Form @submit="onSubmit" :validation-schema="emailSchema">
      <Field
        class="mb-1 w-full rounded-md border-gray-200 font-normal focus:border-green-500 focus:ring-green-500 focus-visible:border-green-500 focus-visible:ring-green-500"
        name="correo"
        v-model="email"
        placeholder="Ingresa tu correo electrónico"
        type="email"
        :validateOnBlur="false"
      />
      <ErrorMessage class="text-red-500" name="correo" />
      <p v-if="mostrarMensaje" class="text-sm" :class="[feedBack.color]">
        {{ feedBack.mensaje }}
      </p>
      <Button class="mt-1.5 w-full" type="secondary" :loading="loader">
        Suscribirse
      </Button>
    </Form>
  </div>
</template>
<script setup lang="ts">
  import { Field, Form, ErrorMessage } from "vee-validate";
  import { ref, reactive } from "@vue/runtime-core";
  import Button from "@components/ui/Button.vue";
  import { registrarNewsletter } from "@api/client/users";
  import { emailSchema } from "@lib/formSchemas";

  const email = ref("");
  const mostrarMensaje = ref(false);
  const loader = ref(false);

  const feedBack = reactive({ mensaje: "", color: "" });

  const onSubmit = async () => {
    loader.value = true;
    if (!emailSchema.isValidSync({ correo: email.value })) return;
    const respuesta = await registrarNewsletter(email.value);
    if (respuesta.estado == "ok") {
      feedBack.mensaje = respuesta.mensaje;
      feedBack.color = "text-green-600";
    } else {
      feedBack.mensaje = respuesta.errores;
      feedBack.color = "text-red-500";
    }
    loader.value = false;
    mostrarMensaje.value = true;
    setTimeout(() => {
      mostrarMensaje.value = false;
    }, 3000);
  };
</script>
