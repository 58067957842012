<template>
  <div class="sm:hidden">
    <Collapse title="Categorías" underline botonGrande colorLime>
      <template #title>
        <h2 class="text-xl font-bold">Categorías</h2>
      </template>
      <section>
        <ul class="pt-2">
          <li
            v-for="categoria in categorias"
            class="py-1.5 text-lg font-normal md:hover:text-green-500"
          >
            <a :href="'/categoria/' + categoria.ruta">
              {{ categoria.nombre }}
            </a>
          </li>
        </ul>
      </section>
    </Collapse>
    <Collapse title="Entradas" underline botonGrande colorLime>
      <template #title>
        <h2 class="text-xl font-bold">Entradas</h2>
      </template>
      <section>
        <ul class="pt-2">
          <li
            v-for="entrada in entradasDisponibles"
            class="py-1.5 text-lg font-normal md:hover:text-green-500"
          >
            <a :href="entrada.link">
              {{ entrada.nombre }}
            </a>
          </li>
        </ul>
      </section>
    </Collapse>
    <Collapse title="Contacto" underline botonGrande colorLime>
      <template #title>
        <h2 class="text-xl font-bold">Contacto</h2>
      </template>
      <section>
        <ul class="pt-2">
          <li
            v-for="contacto in contactosDisponibles"
            class="py-1.5 text-lg font-normal md:hover:text-green-500"
            @mouseenter="activarClase(contacto)"
            @mouseleave="activarClase(contacto)"
          >
            <a
              :href="contacto.link"
              class="flex align-middle"
              :target="contacto.target"
            >
              <svg
                v-if="contacto.icono == 'whatsapp'"
                class="mr-2 mt-0.5 h-6 w-6"
                :class="{ 'fill-green-600': estadoClase }"
              >
                <path
                  d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
                />
              </svg>

              <component
                v-else
                :is="contacto.icono == 'envelope' ? EnvelopeIcon : AtSymbolIcon"
                class="mr-2 mt-0.5 h-6 w-6"
              >
              </component>
              <span>
                {{ contacto.dato }}
              </span>
            </a>
          </li>
        </ul>
      </section>
    </Collapse>
    <div class="mt-8">
      <span class="text-xl font-semibold">Suscríbete</span>
      <p class="mb-3.5 font-normal">
        Recibe ofertas exclusivas, recomendaciones y consejos
      </p>
      <MailingSubscriptionForm />
    </div>
  </div>

  <div class="hidden sm:inline-flex">
    <div class="mt-3 grid grid-cols-3 gap-10 lg:grid-cols-4">
      <div class="col-start-1 lg:col-start-1">
        <h2 class="mb-4 text-xl font-bold leading-8">Categorías</h2>
        <ul>
          <li
            v-for="categoria in categorias"
            class="py-1.5 text-lg font-normal"
          >
            <a
              :href="'/categoria/' + categoria.ruta"
              class="md:hover:text-green-600 transition-colors"
            >
              {{ categoria.nombre }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-start-2 lg:col-start-2">
        <h2 class="mb-3 text-xl font-bold leading-8">Entradas</h2>
        <ul>
          <li
            v-for="entrada in entradasDisponibles"
            class="py-1.5 text-lg font-normal"
          >
            <a
              :href="entrada.link"
              class="md:hover:text-green-600 transition-colors"
            >
              {{ entrada.nombre }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-start-1 lg:col-start-3">
        <h2 class="mb-3 text-xl font-bold leading-8">Contacto</h2>
        <ul>
          <li
            v-for="contacto in contactosDisponibles"
            class="py-1.5 text-lg font-normal"
          >
            <a
              :href="contacto.link"
              class="flex align-middle md:hover:text-green-600 transition-colors"
              :target="contacto.target"
              @mouseenter="activarClase(contacto)"
              @mouseleave="activarClase(contacto)"
            >
              <svg
                v-if="contacto.icono == 'whatsapp'"
                class="mr-2 mt-0.5 h-6 w-6 transition-colors"
                :class="{ 'fill-green-600': estadoClase }"
              >
                <path
                  d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
                />
              </svg>

              <component
                v-else
                :is="contacto.icono == 'envelope' ? EnvelopeIcon : AtSymbolIcon"
                class="mr-2 mt-0.5 h-6 w-6 flex-none"
              >
              </component>
              <span>
                {{ contacto.dato }}
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-start-3 row-start-1 lg:col-start-4">
        <h2 class="mb-3 text-xl font-bold leading-8">Suscríbete</h2>
        <p class="mb-3 text-sm text-gray-700">
          Recibe oferta exclusivas, recomendaciones y consejos.
        </p>
        <MailingSubscriptionForm />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import Collapse from "@components/ui/Collapse.vue";
  import { EnvelopeIcon, AtSymbolIcon } from "@heroicons/vue/24/outline";
  import { ref, computed } from "@vue/runtime-core";
  import MailingSubscriptionForm from "@components/MailingSubscriptionForm.vue";
  import type { Categoria } from "@lib/interfaces";

  interface EntradasFooter {
    nombre: string;
    link: string;
    activo: boolean;
  }

  interface ContactosFooter {
    icono: string;
    dato: string;
    link: string;
    activo: boolean;
    target?: string;
  }

  interface ObjetoFooter {
    entradas: Array<EntradasFooter>;
    contactos: Array<ContactosFooter>;
  }

  interface Props {
    datos: ObjetoFooter;
    categorias: Array<Categoria & { ruta: string }>;
  }
  const props = defineProps<Props>();

  const entradasNoDisponbiles = ["Invita y gana", "Zonas de despacho"];

  const estadoClase = ref(false);
  const activarClase = (iteracion: ContactosFooter) => {
    if (props.datos.contactos.indexOf(iteracion) == 0) {
      estadoClase.value = !estadoClase.value;
    }
  };

  const entradasDisponibles = computed(() => {
    return props.datos.entradas.filter((entrada) => {
      return (
        entrada.activo == true &&
        !entradasNoDisponbiles.includes(entrada.nombre)
      );
    });
  });

  const contactosDisponibles = computed(() => {
    return props.datos.contactos.filter((contacto) => {
      return contacto.activo == true;
    });
  });
</script>
